import Vue from "vue";

/*TITLE*/
document.title = "PICASSO SUNSET | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "PICASSO SUNSET";
Vue.prototype.$subtitle = "CALLE MIGUEL ÁNGEL 7 |  IBIZA";

/*INTRO*/
Vue.prototype.$promoter = "PICASSO SUNSET";
Vue.prototype.$introSubtitle = "CALLE MIGUEL ÁNGEL 7 |  IBIZA";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "living-room-copia--20230127030151.jpg";
Vue.prototype.$image_kitchen = "cocina-copia--20230127030102.jpg";
Vue.prototype.$image_bath1 = "bath-room-copia--20230127030122.jpg";
Vue.prototype.$image_bath2 = "";
Vue.prototype.$image_room = "render-habitacion-julvi-copia--20230127030137.jpg";
Vue.prototype.$image_facade = "render-exterior-copia--20230127030151.jpg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "living-room-copia--20230127030151.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-copia--20230127030102.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "bath-room-copia--20230127030122.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
];

Vue.prototype.$gallery_images_room = [
  {
    src: "render-habitacion-julvi-copia--20230127030137.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "render-exterior-copia--20230127030151.jpg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20miguel%20ángel%207%20ibiza",
    text: "Calle Miguel Ángel 7, Ibiza",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20miguel%20ángel%207%20ibiza",
    text: "Calle Miguel Ángel 7, Ibiza",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:+34619050447",
    text: "+34619050447",
  },
];
